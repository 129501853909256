import { useState } from "react";
import { ToggleButtonProps } from "./toggle-button-props";

export const ToggleButton = (props: ToggleButtonProps) => {

    const { register, name, isChecked } = props;
    const defaultChecked = isChecked ? isChecked : false;
    const [checkState, setCheckState] = useState<boolean>(defaultChecked);

    const checkStateChange = () => setCheckState((prev) => !prev)

    const switchStyle = {
        display: 'flex',
        alignItems: 'center',
        position: "relative" as const,
        width: "60px",
        height: "34px",
        alignSelf: "start" as const,
    }

    const sliderStyle = {
        position: "absolute" as const,
        cursor: "pointer",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "gray",
        transition: "0.4s",
        borderRadius: "34px"
    }

    const sliderStyleBefore = {
        position: "absolute" as const,
        height: "26px",
        width: "26px",
        left: "4px",
        bottom: "4px",
        backgroundColor: "white",
        transition: "0.4s",
        borderRadius: "50%"
    }

    const sliderStyleChecked = {
        position: "absolute" as const,
        cursor: "pointer",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "green",
        transition: "0.4s",
        borderRadius: "34px"
    }

    const sliderStyleBeforeChecked = {
        position: "absolute" as const,
        height: "26px",
        width: "26px",
        left: "4px",
        bottom: "4px",
        backgroundColor: "white",
        transition: "0.4s",
        borderRadius: "50%",
        transform: "translateX(26px)"
    }



    return (
        <label style={switchStyle}>
            <input type="checkbox" {...register(name, { required: false })} onChange={checkStateChange} checked={checkState} defaultChecked={defaultChecked} />
            <span style={checkState ? sliderStyleChecked : sliderStyle}></span>
            <span style={checkState ? sliderStyleBeforeChecked : sliderStyleBefore}></span>
        </label>
    )
}
