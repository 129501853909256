import { Consent } from "../model/consent.model";

  export const getConsentItem = () => {
    const consentItemRaw = localStorage.getItem("cookie-consent-eu");
    if (consentItemRaw) {
      return JSON.parse(consentItemRaw) as Consent;
    }
    return null;
}
  
  export const analyticsAllowed = () => {
    const consent = getConsentItem();
    if (consent) {
      return consent.analyticsCookies;
    }
    return false;
  }

  export const marketingAllowed = () => {
    const consent = getConsentItem();
    if (consent) {
      return consent.marketingCookies;
    }  
    return false;
}