export const translation = {
  cookieDetailsTitle: "Consent Management for Cookies",
  cookieDetailsCard1: "This website uses cookies and similar technologies ('cookies') for functional and (limited) analytical purposes, such as ensuring security, preventing fraud, detecting errors, and technically delivering advertisements or content.",
  cookieDetailsCard2: "These are necessary to make the website/app function properly, to measure its usage, and to improve our services.",
  cookieDetailsCard3: "In addition, we and third parties use cookies for the purposes listed below, provided you give your consent. Below, you can indicate for each purpose whether you agree.",
  cookieDetailsCard4: "By giving consent for one or more of these purposes, you also consent to the following data processing: receiving and using automatically sent device characteristics for identification, matching and combining offline data, and linking different devices.",
  analyticCookiesToggle: "Analytical Cookies",
  marketingCookiesToggle: "Marketing Cookies",
  btnAcceptAll: "Accept All",
  btnSavePreferences: "Save My Preferences",
  cookieWarningTitle: "Cookie Warning",
  cookieWarningDescription: "This website uses cookies to enhance your user experience. By using this website, you consent to the use of cookies. You can manage cookie preferences via the 'Configure Cookies' button below.",
  btnAccept: "Accept",
  btnConfigure: "Configure Cookies"
}

