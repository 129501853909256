export const translation = {
  cookieDetailsTitle: "Toestemmingsbeheer cookies",
  cookieDetailsCard1: "Op deze website worden cookies en vergelijkbare technieken (“cookies”) gebruikt voor functionele en (beperkte) analytische doeleinden, zoals beveiliging verzekeren, fraude voorkomen en fouten opsporen en het technisch leveren van advertenties of content.",
  cookieDetailsCard2: "Deze zijn nodig om de website/app goed te laten functioneren, om het gebruik ervan te meten en om onze diensten te verbeteren.",
  cookieDetailsCard3: "Daarnaast gebruiken wij en derden cookies voor onderstaande doeleinden, mits u daarvoor toestemming geeft. Hieronder kan u per doeleinde aangeven of u hiermee akkoord gaat.",
  cookieDetailsCard4: "Door toestemming te geven voor een of meer van deze doeleinden, geeft u ook toestemming om de volgende gegevensverwerking uit te voeren: automatisch verzonden apparaat kenmerken ter identificatie ontvangen en gebruiken, offline gegevens met elkaar matchen en combineren, en verschillende apparaten linken.",
  analyticCookiesToggle: "Analytische cookies",
  marketingCookiesToggle: "Marketing cookies",
  btnAcceptAll: "Alles accepteren",
  btnSavePreferences: "Mijn voorkeuren opslaan",
  cookieWarningTitle: "Cookie Warning",
  cookieWarningDescription: "Deze website maakt gebruik van cookies om uw gebruikerservaring te verbeteren. Door deze website te gebruiken, stemt u in met het gebruik van cookies. U kunt het beheer van cookies regelen via de onderstaande knop 'Instellen cookies'.",
  btnAccept: "Accepteren",
  btnConfigure:"Instellen cookies"
}
