import ReactDOM from "react-dom/client";
import { CookieConsentReact } from "../cookie-consent/cookie-consent-react";
import { analyticsAllowed, marketingAllowed } from "../cookie-consent/utils";
export type Position = "popup" | "fixed-bottom" | "custom";

export class CookieConsent extends HTMLElement {
  analyticsCookiesAllowed?: () => void;
  marketingCookiesAllowed?: () => void;

  consentChanged?: (
    marketingCookies: boolean,
    analyticsCookies: boolean
  ) => void;

  connectedCallback() {
    const mountPoint = document.createElement("div");
    mountPoint.style.height = "100%";
    this.attachShadow({ mode: "open" }).appendChild(mountPoint);

    const root = ReactDOM.createRoot(mountPoint);
    const analyticsCookiesAllowed = this.getAttribute(
      "analyticsCookiesAllowed"
    );
    const marketingCookiesAllowed = this.getAttribute(
      "marketingCookiesAllowed"
    );

    const consentChanged = this.getAttribute("consentChanged");
    const showDetails = this.getAttribute("showDetails");
    const position: Position =
      (this.getAttribute("position") as Position) || ("custom" as const);
    if (marketingCookiesAllowed) {
      this.marketingCookiesAllowed =
        window[marketingCookiesAllowed as keyof Window];
    }
    if (analyticsCookiesAllowed) {
      this.analyticsCookiesAllowed =
        window[analyticsCookiesAllowed as keyof Window];
    }

    (window as any).analyticsAllowed = analyticsAllowed;
    (window as any).marketingAllowed = marketingAllowed;

    if (consentChanged) {
      this.consentChanged = window[consentChanged as keyof Window];
    }

    // Function to get the value of a query parameter from the URL
    function getQueryParam(param: string) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    }

    // Function to determine the language based on the query parameter or browser language
    function getLanguage() {
      const langQueryParam = getQueryParam("lang");
      return langQueryParam || navigator.language.split("-")[0] || "en";
    }

    const language = this.getAttribute("language") ?? getLanguage();

    root.render(
      <div>
        <style>{`
        .cookie-form-fixed-bottom {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            padding: 1.5rem !important;
            box-shadow: 2px 2px 5px grey;
        }


        .cookie-form-popup {
            position: fixed;
            z-index: 1000;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 2px 2px 5px grey;
        }
        

        @media screen and (max-width: 390px) {
            .cookie-form-popup {
                position: fixed;
                z-index: 1000;
                top: 0;
                left: 0;
                transform: none;
                max-height: 100%;
                box-shadow: 2px 2px 5px grey;
            }
        }
        .cookie-form-custom {
            box-shadow: 2px 2px 5px grey;
        }
        .toast {
            color: #5f6f83;
            background-color: var(--allct-main-bg-color, #fff);
            max-height: calc(100vh - 90px);
            overflow-y: scroll;
            max-width: 100% !important;
            width: 100% !important;
        }
        .toast-body {
            display: flex;
            flex-direction: column;
            padding: 1.5rem;
        }
        .button {
            padding: 0.375rem 0.75rem;
            margin: 10px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #fff;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            background-color: var(--allct-button-bg-color,#5f6f83);
            border-color: var(--allct-button-bg-color,#5f6f83);
            border: 1px solid #fff;
            border-radius: 0.375rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .form-group {
            margin-bottom: 1rem;
            margin-right: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 1rem;
        }
        .ml-auto {
            margin-left: auto!important;
        }
        `}</style>
        <CookieConsentReact
          analyticsCookiesAllowed={this.analyticsCookiesAllowed}
          marketingCookiesAllowed={this.marketingCookiesAllowed}
          consentChanged={this.consentChanged}
          showDetails={showDetails === "true"}
          position={position}
          language={language}
        />
      </div>
    );
  }
}

customElements.define("cookie-consent", CookieConsent);
