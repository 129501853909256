import { useEffect, useState } from "react";
import { CookieWarning } from "./cookie-warning";
import { CookieDetails } from "./cookie-details";
import { CookieConsentFormProps } from "./cookie-consent-form-props";


export const CookieConsentForm = (props: CookieConsentFormProps) => {
    const [showDetails, setShowDetails] = useState(false);
    const { consentGiven, showDetails: showDetailsProp, consent, position } = props;
    const cookieFormClasses = `cookie-form-${position}`;

    useEffect(() => {
        setShowDetails(showDetailsProp);
    }, [showDetailsProp]);

    const onAcceptAll = () => {
        consentGiven(true, true);
    }

    const onConsentGiven = (marketingCookies: boolean, analyticsCookies: boolean) => {
        consentGiven(marketingCookies, analyticsCookies);
    }

    const onDetails = () => {
        setShowDetails(true);
    }


    return (<div className={cookieFormClasses}>
        <div className="toast">
            {!showDetails ? <CookieWarning acceptAllCookies={onAcceptAll} showDetails={onDetails} /> : <CookieDetails consentGiven={onConsentGiven} consent={consent} />}
        </div>
    </div>)
}
