import { CookieConsentProps } from "./cookie-consent-props";
import { CookieConsentForm } from "./cookie-consent-form";
import { useEffect, useState } from "react";
import { Consent } from "../model/consent.model";
import { LanguageProvider } from "../localisation/LanguageContext";
import { translation as translation_en } from "../locales/translation_en";
import { translation as translation_nl } from "../locales/translation_nl";

export const CookieConsentReact = (props: CookieConsentProps) => {
  const [consentItem, setConsentItem] = useState<Consent>({
    consentGiven: false,
    dateConsentGiven: null,
    marketingCookies: false,
    analyticsCookies: false,
  });

  const {
    analyticsCookiesAllowed,
    marketingCookiesAllowed,
    consentChanged,
    showDetails,
    position,
    language,
  } = props;

  useEffect(() => {
    const consentItemRaw = localStorage.getItem("cookie-consent-eu");
    if (consentItemRaw) {
      setConsentItem(JSON.parse(consentItemRaw) as Consent);
    }
  }, []);

  useEffect(() => {
    if (consentItem.analyticsCookies && analyticsCookiesAllowed) {
      analyticsCookiesAllowed();
    }
    if (consentItem.marketingCookies && marketingCookiesAllowed) {
      marketingCookiesAllowed();
    }
  }, [consentItem, analyticsCookiesAllowed, marketingCookiesAllowed]);

  const consentGivenHandler = (
    marketingCookies: boolean,
    analyticsCookies: boolean
  ) => {
    const consent: Consent = {
      consentGiven: true,
      dateConsentGiven: new Date(),
      marketingCookies: marketingCookies,
      analyticsCookies: analyticsCookies,
    };

    localStorage.setItem("cookie-consent-eu", JSON.stringify(consent));
    setConsentItem(consent);
    if (consentChanged) {
      consentChanged(marketingCookies, analyticsCookies);
    }
  };

  const translationConfig = {
    en: translation_en,
    nl: translation_nl,
  };

  function isValidLang(lang: string | null | undefined) {
    if (lang && translationConfig.hasOwnProperty(lang)) {
      return lang;
    }
    return undefined;
  }

  return !consentItem.consentGiven || showDetails ? (
    <LanguageProvider
      translationConfig={translationConfig}
      defaultLanguage={isValidLang(language) || "en"}
    >
      <CookieConsentForm
        consentGiven={consentGivenHandler}
        showDetails={showDetails}
        position={position}
        consent={consentItem}
      />
    </LanguageProvider>
  ) : null;
};
