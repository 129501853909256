import { FieldValues, useForm } from "react-hook-form";
import { CookieDetailProps } from "./cookie-detail-props";
import { ToggleButton } from "../ui-components/toggle-button";
import { useLanguage } from "../localisation/LanguageContext";

export const CookieDetails = (props: CookieDetailProps) => {
  const { consentGiven, consent } = props;
  const { register, handleSubmit } = useForm();
  const { t } = useLanguage();

  const onSubmit = (data: FieldValues) => {
    consentGiven(data.inputMarketingCookies, data.inputAnalyticCookies);
  };

  const acceptAll = () => {
    consentGiven(true, true);
  };

  return (
    <div className="toast-body">
      <h4>{t("cookieDetailsTitle")}</h4>
      <p>{t("cookieDetailsCard1")}</p>
      <p>{t("cookieDetailsCard2")}</p>
      <p>{t("cookieDetailsCard3")}</p>
      <p>{t("cookieDetailsCard4")}</p>
      <div style={{ marginTop: "10px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="inputAnalyticCookies" style={{ display: "block" }}>
              {t("analyticCookiesToggle")}
            </label>
            <ToggleButton
              name="inputAnalyticCookies"
              register={register}
              key={consent.analyticsCookies ? "true" : "false"}
              isChecked={consent.analyticsCookies}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputMarketingCookies" style={{ display: "block" }}>
              {t("marketingCookiesToggle")}
            </label>
            <ToggleButton
              name="inputMarketingCookies"
              register={register}
              key={consent.marketingCookies ? "true" : "false"}
              isChecked={consent.marketingCookies}
            />
          </div>

          <div className="ml-auto">
            <button
              type="button"
              className="button"
              id="btnAccept"
              aria-label="Accepteer alle cookies"
              onClick={acceptAll}
            >
              {t("btnAcceptAll")}
            </button>
            <button
              type="submit"
              className="button"
              aria-label="Opslaan voorkeuren cookies"
              id="btnSave"
            >
              {t("btnSavePreferences")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
